import { Content, Header, InfoCard, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import LogoNavbarMini from '../../assets/logo-navbar-mini.png';

import React from 'react';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: 'none',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.navigation.selectedColor,
    padding: theme.spacing(4, 2),
    textAlign: 'center',
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    fontSize: 40,
    // backgroundColor: theme.palette.background.default,
    minHeight: 200, // Ensure consistent card height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.navigation.selectedColor,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  infoCardNoShadow: {
    boxShadow: 'none',
    height: '100%',
    marginTop: theme.spacing(3), // Added top margin
    marginBottom: theme.spacing(3), // Added bottom margin
  },
  toolGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  toolItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '8px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  logoFont: {
    height: '40px',
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header
          title="Welcome to OnePaas"
          subtitle="Your one-stop DevOps platform"
        />
        <Content>
          <Grid
            container
            justifyContent="center"
            spacing={6}
            style={{ marginTop: 20 }}
          >
            {/* Search Bar */}
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search for documentation, tools, services..."
              />
            </Grid>

            {/* Featured Tools Section */}
            <Grid container item xs={12} spacing={4}>
              <Grid item xs={12} md={6}>
                <InfoCard
                  title="Featured Tools"
                  className={classes.infoCardNoShadow}
                >
                  <div className={classes.toolGrid}>
                    <div className={classes.toolItem}>
                      <img
                        src={LogoNavbarMini}
                        alt="navbar-logo"
                        className={classes.logoFont}
                      />
                      <Typography>Kubernetes Dashboard</Typography>
                    </div>
                    <div className={classes.toolItem}>
                      <img
                        src={LogoNavbarMini}
                        alt="navbar-logo"
                        className={classes.logoFont}
                      />{' '}
                      <Typography>Monitoring Tools</Typography>
                    </div>
                    <div className={classes.toolItem}>
                      <img
                        src={LogoNavbarMini}
                        alt="navbar-logo"
                        className={classes.logoFont}
                      />{' '}
                      <Typography>Documentation Site</Typography>
                    </div>
                  </div>
                </InfoCard>
              </Grid>

              {/* Latest Updates Section */}
              <Grid item xs={12} md={6}>
                <InfoCard
                  title="Platform Updates"
                  className={classes.infoCardNoShadow}
                >
                  <div className={classes.cardContent}>
                    <Typography variant="subtitle1">
                      - New Kubernetes monitoring tools added. <br />
                      - patches applied on core services.
                    </Typography>
                  </div>
                </InfoCard>
              </Grid>
            </Grid>

            {/* Custom Section */}
            <Grid container item xs={12} spacing={4}>
              <Grid item xs={12} md={6}>
                <InfoCard
                  title="Popular Services"
                  className={classes.infoCardNoShadow}
                >
                  <div className={classes.cardContent}>
                    <Typography variant="subtitle1">
                      - Microservice A <br />
                      - Microservice B <br />
                      - Backend API C <br />
                    </Typography>
                    <Button className={classes.sectionButton}>
                      Explore Services
                    </Button>
                  </div>
                </InfoCard>
              </Grid>

              {/* Metrics Dashboard Section */}
              <Grid item xs={12} md={6}>
                <InfoCard
                  title="Metrics Dashboard"
                  className={classes.infoCardNoShadow}
                >
                  <div className={classes.cardContent}>
                    <Typography variant="subtitle1">- Uptime: 99.9%</Typography>
                    <Typography variant="subtitle1">
                      - Response Time: 250ms
                    </Typography>
                    <Typography variant="subtitle1">- Errors: 2%</Typography>
                    <Button className={classes.sectionButton}>
                      View Details
                    </Button>
                  </div>
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
