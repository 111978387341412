import { Page } from '@backstage/core-components';
import {
  discoveryApiRef,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Snackbar,
  SnackbarCloseReason,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { useAsync } from '@react-hookz/web';
import React, { useEffect, useState } from 'react';
import LoginLogo from '../../assets/logo.png';
import LoginBG from '../../assets/logon-bg.png';
import { LoginForm } from './Form';
import { LdapSignInIdentity } from './Identity';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#F2F2F2',
    backgroundImage: `url(${LoginBG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
  },
  formContainer: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    padding: theme.spacing(4),
    // fontWeight: 'bolder',
    marginBottom: 50,
    width: 250,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(4),
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '20rem',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export type LdapSignInPageProps = SignInPageProps & {
  provider: string;
  children?: React.ReactNode | null;
  onSignInError?: (error: Error) => void;
  options?: {
    helperTextPassword?: string;
    helperTextUsername?: string;
    validateUsername?: (usr: string) => boolean;
    validatePassword?: (pass: string) => boolean;
    usernameLabel?: string;
  };
};

export const LdapSignInPage = (props: LdapSignInPageProps) => {
  const classes = useStyles();
  const discoveryApi = useApi(discoveryApiRef);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [identity] = useState(
    new LdapSignInIdentity({
      provider: props.provider,
      discoveryApi,
    }),
  );

  const [{ status, error }, { execute }] = useAsync(async () => {
    try {
      await identity.login({ username, password });
      props.onSignInSuccess(identity);
    } catch (e: any) {
      setToastMessage(e.body.error.message || 'An error occurred');
      setOpen(true);
      throw e;
    }
  });

  const [{ status: statusRefresh }, { execute: executeRefresh }] = useAsync(
    async () => {
      await identity.fetch();
      props.onSignInSuccess(identity);
    },
  );

  useEffect(() => {
    executeRefresh();
  }, []);

  function onSubmit(u: string, p: string) {
    setUsername(u);
    setPassword(p);
    setTimeout(execute, 0);
  }

  if (
    status === 'loading' ||
    statusRefresh === 'loading' ||
    statusRefresh === 'not-executed'
  ) {
    return <CircularProgress />;
  } else if (status === 'success' || statusRefresh === 'success') {
    return null;
  }

  function onSignInError(err: Error) {
    props?.onSignInError?.(err);
  }

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    return;
  };

  return (
    <Page themeId="auth">
      <Container disableGutters maxWidth={false} className={classes.root}>
        {/* Snackbar for errors */}
        <Snackbar
          open={open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
        >
          <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
            {toastMessage}
          </Alert>
        </Snackbar>

        {/* Centered login form */}
        <Grid
          container
          item
          xs={12}
          sm={8}
          md={5}
          lg={5}
          xl={3}
          className={classes.formContainer}
        >
          <Box className={classes.boxWrapper}>
            <img alt="opepass" src={LoginLogo} className={classes.logo} />

            {/* Login Form */}
            <div className={classes.formWrapper}>
              <LoginForm
                onSubmit={onSubmit}
                onSignInError={onSignInError}
                error={error}
                helperTextPassword={
                  props?.options?.helperTextPassword || 'Enter your password'
                }
                helperTextUsername={
                  props?.options?.helperTextUsername || 'Enter your username'
                }
                usernameLabel="Username"
                {...props.options}
              />
            </div>
          </Box>
        </Grid>
      </Container>
    </Page>
  );
};
